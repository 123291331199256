import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"

import { Logo } from "../../svg"
import Button from "../button"
import Section from "./section-component"
import Link from "../link"
import MixpanelLinkTracker from "../mixpanel-link-tracker"
import { events } from "../../services/mixpanel"

const socialIcons = {
  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M18 9.95a8.084 8.084 0 00-1.309-4.313 8.014 8.014 0 00-3.409-2.928A7.956 7.956 0 004.767 3.96 8.055 8.055 0 002.334 7.75a8.097 8.097 0 00-.027 4.51 8.056 8.056 0 002.389 3.816A7.98 7.98 0 008.75 18v-5.705h-2V9.95h2V8.17a2.863 2.863 0 01.766-2.286 2.829 2.829 0 012.234-.874c.6.008 1.198.062 1.79.161v2.013h-1a1.153 1.153 0 00-.953.32 1.167 1.167 0 00-.347.947v1.52h2.22l-.36 2.344h-1.85v5.635a7.988 7.988 0 004.848-2.741A8.08 8.08 0 0018 9.95z"></path>
    </svg>
  ),
  rss: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M4.167 17.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.177c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49.458 0 .85.164 1.177.49.326.327.49.719.49 1.177 0 .459-.164.851-.49 1.177-.327.327-.719.49-1.177.49zm11.25 0c-.361 0-.663-.132-.907-.396a1.625 1.625 0 01-.427-.937 11.43 11.43 0 00-1.093-3.782 11.69 11.69 0 00-2.24-3.135 11.69 11.69 0 00-3.135-2.24 11.43 11.43 0 00-3.782-1.093 1.624 1.624 0 01-.937-.427 1.182 1.182 0 01-.396-.907c0-.36.125-.656.375-.885.25-.23.549-.33.896-.302 1.708.153 3.309.587 4.802 1.302a14.229 14.229 0 013.948 2.781 14.23 14.23 0 012.781 3.948 13.882 13.882 0 011.302 4.802c.028.347-.073.646-.302.896-.23.25-.524.375-.885.375zm-5 0a1.24 1.24 0 01-.896-.365 1.66 1.66 0 01-.48-.885 6.326 6.326 0 00-.656-1.885 6.795 6.795 0 00-1.177-1.573 6.797 6.797 0 00-1.573-1.177 6.325 6.325 0 00-1.885-.657 1.66 1.66 0 01-.885-.479 1.24 1.24 0 01-.365-.896c0-.36.125-.66.375-.895.25-.237.549-.327.896-.271a8.821 8.821 0 012.844.885 9.34 9.34 0 014.083 4.083c.451.882.746 1.83.885 2.844.056.347-.034.646-.27.896a1.18 1.18 0 01-.896.375z"></path>
    </svg>
  ),
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.734 5.636H5.381l3.456 4.942-3.277 3.786h1.072l2.682-3.106 2.172 3.106h3.353l-3.673-5.25 3.003-3.478h-1.055l-2.422 2.799-1.958-2.799zm4.482 7.863h-1.301L7.003 6.497h1.3l4.913 7.002z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  instagram: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M13.248 7.543a1.38 1.38 0 00-.791-.791 2.305 2.305 0 00-.774-.144c-.439-.02-.57-.025-1.683-.025s-1.244.005-1.683.025a2.305 2.305 0 00-.774.144 1.38 1.38 0 00-.791.791c-.092.248-.14.51-.144.774-.02.439-.025.57-.025 1.683s.005 1.244.025 1.683c.003.265.052.527.144.774a1.381 1.381 0 00.791.791c.248.092.51.14.774.144.439.02.57.024 1.683.024s1.244-.004 1.683-.025c.265-.003.527-.051.774-.143a1.38 1.38 0 00.791-.791c.092-.248.14-.51.144-.774.02-.439.024-.57.024-1.683s-.004-1.244-.025-1.683a2.302 2.302 0 00-.143-.774zM10 12.14a2.14 2.14 0 110-4.28 2.14 2.14 0 010 4.28zm2.224-3.864a.5.5 0 110-1 .5.5 0 010 1zM11.39 10a1.39 1.39 0 11-2.78 0 1.39 1.39 0 012.78 0zM10 2a8 8 0 100 16 8 8 0 000-16zm4.142 9.717a3.059 3.059 0 01-.194 1.012 2.13 2.13 0 01-1.219 1.219 3.06 3.06 0 01-1.01.194c-.446.02-.587.025-1.719.025-1.132 0-1.273-.005-1.718-.025a3.059 3.059 0 01-1.011-.194 2.13 2.13 0 01-1.218-1.219 3.06 3.06 0 01-.195-1.01c-.02-.446-.025-.587-.025-1.719 0-1.132.005-1.273.025-1.718.007-.346.073-.688.194-1.011A2.13 2.13 0 017.27 6.05a3.058 3.058 0 011.01-.193c.446-.02.587-.025 1.719-.025 1.132 0 1.273.005 1.718.025.346.007.688.073 1.011.194a2.13 2.13 0 011.219 1.219c.12.323.186.665.194 1.01.02.446.025.587.025 1.719 0 1.132-.005 1.273-.025 1.717z"></path>
    </svg>
  ),
}

const Footer = () => {
  return (
    <div className="py-16 px-5 container-12 lg:py-20">
      <div className="flex flex-col gap-8 mb-8 lg:flex-row lg:justify-between lg:mb-10 lg:items-center">
        <Link to="/">
          <Logo className="fill-current text-black w-[115.5px] h-[24px] mx-auto lg:mx-0" />
        </Link>
        <MixpanelLinkTracker
          query="#footer-cta"
          eventName={events.clicked_premium_cta}
        >
          <Button
            size={2}
            variant="solid"
            color="primary"
            className="w-full lg:w-fit"
            asChild
          >
            <a
              id="footer-cta"
              href="/premium/pricing/?ref_source=sitepoint&unlock=true&ref_medium=hp-footer"
            >
              Start Free Trial
            </a>
          </Button>
        </MixpanelLinkTracker>
      </div>
      <div className="grid grid-cols-2 gap-y-8 mb-8 lg:mb-10 lg:grid-cols-4">
        <Section
          title="Stuff we do"
          items={[
            { to: "/premium/library/", label: "Premium", external: true },
            { to: "/newsletters/", label: "Newsletters" },
            {
              to: "/premium/library/paths/",
              label: "Learning paths",
              external: true,
            },
            { to: "/premium/library/", label: "Library", external: true },
            { to: "/community/", label: "Forums", external: true },
          ]}
        />
        <Section
          title="Contact"
          items={[
            { to: "/contact-us/", label: "Contact us" },
            {
              to: "https://sitepointhq.notion.site/Sitepoint-FAQs-619b2b88af4f4a5db27beade7ca2cce6",
              label: "FAQ",
            },
            {
              to: "https://sitepoint.typeform.com/to/HtAXVN",
              label: "Publish your book with us",
            },
            {
              to: "/write-for-us/",
              label: "Write an article with us",
            },
            { to: "/partnerships/", label: "Advertise" },
          ]}
        />
        <Section
          title="About"
          items={[
            { to: "/about-us/", label: "Our story" },
            {
              to: "/premium-for-teams/",
              label: "Corporate memberships",
            },
            { to: "/legals/", label: "Terms of use" },
            { to: "/privacy-policy/", label: "Privacy policy" },
          ]}
        />
        <Section
          title="Connect"
          items={[
            {
              icon: socialIcons.rss,
              to: "/sitepoint.rss",
              label: "RSS",
              external: true,
              target: "_blank",
              rel: "noopener noreferrer",
            },
            {
              icon: socialIcons.facebook,
              to: "https://www.facebook.com/sitepoint",
              label: "Facebook",
              target: "_blank",
              rel: "noopener noreferrer",
            },
            {
              icon: socialIcons.instagram,
              to: "https://www.instagram.com/sitepointdotcom/?hl=en",
              label: "Instagram",
              target: "_blank",
              rel: "noopener noreferrer",
            },
            {
              icon: socialIcons.twitter,
              to: "https://twitter.com/sitepointdotcom",
              label: "Twitter (X)",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          ]}
          footer={
            <div className="text-1-regular text-neutral-10">
              This site is protected by reCAPTCHA and the Google
              <Link
                to="https://policies.google.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
                className="text-current"
              >
                {" "}
                Privacy Policy{" "}
              </Link>
              and
              <Link
                to="https://policies.google.com/terms"
                className="text-current"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                Terms of Service{" "}
              </Link>
              apply.
            </div>
          }
        />
      </div>
      <div className="text-1-medium text-neutral-10 text-center mb-8">
        &copy; 2000 &ndash; {new Date().getFullYear()} SitePoint Pty. Ltd.
      </div>
      <div className="mx-auto w-fit lg:mx-0 lg:float-right">
        <Button
          variant="ghost"
          color="neutral"
          size={1}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth", // For smooth scrolling
            })
          }}
        >
          Back to top
          <FontAwesomeIcon icon={faChevronUp} size="xs" className="ml-2" />
        </Button>
      </div>
    </div>
  )
}

export default Footer
