import React, { useEffect } from "react"

import { mixpanel } from "../services/mixpanel"

export default function MixpanelLinkTracker({ query, eventName, children }) {
  useEffect(() => {
    if (query && eventName) {
      mixpanel?.track_links(query, eventName)
    }
  }, [query, eventName])

  return <>{children}</>
}
