import React, { createContext, useReducer, useContext } from "react"

import useTakeoverAds from "../hooks/use-takeover-ads"

const initialState = {
  // Do not load ads if `initialized` is false
  initialized: false,
  adProvider: false,
  adFree: true,
}

const AdStateContext = createContext(initialState)
const AdDispatchContext = createContext()

const types = {
  INITIALIZE: "INITIALIZED",
}

function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case types.INITIALIZE: {
      return { ...state, ...payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

function AdProvider({ children }) {
  const takeoverAds = useTakeoverAds()
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    return {
      ...initialState,
      adProvider: !takeoverAds,
    }
  })

  return (
    <>
      <AdStateContext.Provider value={state}>
        <AdDispatchContext.Provider value={dispatch}>
          {children}
        </AdDispatchContext.Provider>
      </AdStateContext.Provider>
    </>
  )
}

function useAdState() {
  return useContext(AdStateContext)
}

function useAdDispatch() {
  return useContext(AdDispatchContext)
}

export { AdProvider, types, useAdState, useAdDispatch }
export default function Provider({ element }) {
  return <AdProvider>{element}</AdProvider>
}
