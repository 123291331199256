import React from "react"

import Main from "./main"

const layoutMap = {
  default: Main,
  none: ({ children }) => <>{children}</>,
}

export default function Layout({
  layout,
  newsletter,
  children,
  fullWidth,
}: {
  layout: keyof typeof layoutMap
  children: React.ReactNode
  newsletter?: boolean
  fullWidth?: boolean
}) {
  const Component = layoutMap[layout ?? "default"]

  if (Component)
    return (
      <Component newsletter={newsletter} fullWidth={fullWidth}>
        {children}
      </Component>
    )

  return children
}
