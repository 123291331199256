import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { cva, type VariantProps } from "class-variance-authority"

import cn from "../utils/classname"

const linkVariants = cva("", {
  variants: {
    color: {
      primary: [
        "text-primary-alpha-12",
        "hover:text-primary-alpha-11 focus-visible:text-primary-alpha-11",
      ],
      neutral: [
        "text-neutral-12",
        "hover:text-neutral-alpha-11 focus-visible:text-neutral-alpha-11",
      ],
    },
    highContrast: {
      true: "",
      false: "",
    },
    underline: {
      true: "underline underline-offset-2",
    },
  },
  compoundVariants: [
    {
      color: "primary",
      highContrast: false,
      className: [
        "text-primary-alpha-11",
        "hover:text-primary-alpha-12 focus-visible:text-primary-alpha-12",
      ],
    },
    {
      color: "neutral",
      highContrast: false,
      className: [
        "text-neutral-alpha-11",
        "hover:text-neutral-alpha-12 focus-visible:text-neutral-alpha-12",
      ],
    },
  ],
  defaultVariants: {
    highContrast: true,
  },
})

export interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "color" | "href">,
    VariantProps<typeof linkVariants> {
  to: string
  activeClassName?: string
  partiallyActive?: boolean
  external?: boolean
}

export type LinkElement = HTMLAnchorElement & GatsbyLink<{}>

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = React.forwardRef<LinkElement, LinkProps>(
  (
    {
      className,
      to,
      activeClassName,
      partiallyActive,
      external,
      children,
      color,
      highContrast,
      ...props
    },
    ref
  ) => {
    const internal = !external && /^\/(?!\/)/.test(to)
    const baseClassName = cn(linkVariants({ color, highContrast }), className)

    if (internal) {
      return (
        <GatsbyLink
          ref={ref}
          className={baseClassName}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          to={to}
          {...props}
        >
          {children}
        </GatsbyLink>
      )
    }
    return (
      <a ref={ref} href={to} className={baseClassName} {...props}>
        {children}
      </a>
    )
  }
)
Link.displayName = "Link"

export default Link
