import mixpanel from "mixpanel-browser"

mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN, {
  debug: process.env.NODE_ENV !== "production",

  // Should match the Data Residency host of the project
  // https://docs.mixpanel.com/docs/tracking/reference/javascript#eu-data-residency
  api_host: "https://api-eu.mixpanel.com",

  // This is manually done in gatsby-browser's onRouteUpdate
  track_pageview: false,

  persistence: "localStorage",
})

const events = {
  clicked_premium_cta: "clicked_premium_cta",
  clicked_browse_library: "clicked_browse_library",
  clicked_go_to_community: "clicked_go_to_community",
  ad_click: "ad_click",
  ad_impression: "ad_impression",
}

export { mixpanel, events }
