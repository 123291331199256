import { extendTailwindMerge } from "tailwind-merge"
import clsx, { type ClassValue } from "clsx"

/**
 * Define a class group to prevent a custom Tailwind class from being omitted by twMerge.
 * See Tailwind's config file for acceptable values.
 */

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        {
          text: [
            ...Array.from({ length: 14 }).map((_, index) => `jar-${index + 1}`),
            ...Array.from({ length: 11 }).map((_, index) =>
              (index + 1).toString()
            ),
          ],
        },
      ],
    },
  },
})

const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

export default cn
