/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis
 */
import "@fontsource-variable/inter"
import "./src/styles/global.css"

import { addToDataLayer } from "./src/services/gtm"
import { mixpanel } from "./src/services/mixpanel"

const posthogConfig = {
  "new-site-redesign": {
    pageRedirects: {},
  },
}

export const onClientEntry = async () => {
  /**
   * Only enable in production by default
   * Comment out this code block if doing testing or development work related or affecting Mixpanel
   */
  if (process.env.NODE_ENV !== "production") {
    mixpanel?.disable()
  }

  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }

  const posthog = window.posthog
  // redirect pages based on the new-design redesign feature flag
  if (posthog?.getFeatureFlag) {
    // if post hog getFeatureFlag is present
    if (
      posthog.getFeatureFlag("new-site-redesign") === "test" &&
      posthogConfig["new-site-redesign"].pageRedirects[window.location.pathname]
    ) {
      // if it exists then redirect to that page.
      window.location.pathname =
        posthogConfig["new-site-redesign"].pageRedirects[
          window.location.pathname
        ]
    }
  }
}

export const onRouteUpdate = async () => {
  mixpanel?.track_pageview()

  // Ref: gatsby-plugin-google-tagmanager
  // Wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    addToDataLayer({ event: "gatsby-route-change" })
  }, 50)
}

export { wrapPageElement, wrapRootElement } from "./gatsby-shared"
