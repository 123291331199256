import React from "react"
import Link from "../link"

const Section = ({ title, items, footer }) => (
  <div className="flex flex-col gap-4">
    <div className="text-2 font-semibold text-neutral-11">{title}</div>
    {items.map(({ label, icon, ...rest }, index) => (
      <Link
        {...rest}
        key={index}
        className="font-medium inline-flex items-center space-x-1"
        color="neutral"
      >
        {icon ? (
          <>
            {icon}
            <span>{label}</span>
          </>
        ) : (
          label
        )}
      </Link>
    ))}
    {footer}
  </div>
)

export default Section
