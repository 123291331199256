import { useStaticQuery, graphql } from "gatsby"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"

export default function useTakeoverAds() {
  const { sanityAdSettings } = useStaticQuery(graphql`
    query {
      sanityAdSettings {
        takeoverAds {
          _id
        }
        takeoverStart
        takeoverEnd
      }
    }
  `)

  const dateToday = new Date()

  return (
    !!sanityAdSettings?.takeoverAds?._id &&
    !isBefore(dateToday, new Date(sanityAdSettings?.takeoverStart)) &&
    !isAfter(dateToday, new Date(sanityAdSettings?.takeoverEnd))
  )
}
